.DropDown {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(//cdn2.hubspot.net/hubfs/302335/select-arrow.png) no-repeat;
    background-size: 13px 8px;
    background-position: 97% 51%;
    border: 0;
    border-radius: 4px;
    margin-top: 2px;
    margin-bottom: 20px;
    font-size: 16px;
    background-color: #FFF;
    padding: 16px;
    width: 100%;
    margin-right: 0;
}