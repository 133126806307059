/*   
  .textareaWrapper {
    display: inline-block;
    background-image: linear-gradient(#F1F1F1 50%, #F9F9F9 50%);
    background-size: 100% 32px;
    background-position: left 10px;
  } */

  .AdminContainer {
    padding: 1rem;
  }
  .TabContainer {
    align-items: flex-start !important;
    display: flex;
    background-color: #f2f6f7;
    color: #004271;
    padding: 1vw;
  }
  .TabBtnContainer {
    display: flex;
    flex-direction: column;
  }
  .TabBtn {
    background-color: transparent;
    min-width: 100px;
    padding: 2rem 1.4rem;
    font-size: 1.6rem;
    font-weight: 700;
    border: none;
    color: #004271;
    margin: 10px 0;
  }
  .TabBtn.Active {
    background-color: white;
  }
  .TabContent {
    width: 100%;
    background-color: white !important;
    min-height: 750px;
    position: relative;
    padding:10px
  }
  .TabPane {
    padding: 1.5rem;
  }

  .HeaderDiv {
    display: flex;
    background-color: #f2f6f7;
  }
  .Select {
    display: flex;
    align-items: baseline;
    padding: 0 10px;
  }

  .Label{
    font-size: 15px;
  }

  .TextAreaContainer{
    display:flex;
  }

  .TableIconContainer {
    margin: 0 8px;
    font-size: 16px;
    border-radius: 5px;
    color: white;
    background: #818a90;
    border: 1px solid #818a90;
    padding: 4px 15px;
  }
  .TableIconContainer:hover {
    background-color: #012b49 !important;
    color: #fff !important;
    border-color: #012b49;
  }


  .PolicyContainer{
    display: flex;
    flex-direction: column;
  }

  .SignIn{
    display: flex;
    justify-content: flex-end;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
    padding: 1rem;
}


.BannerHead {
  font-size: 2rem;
  margin: 10px 0px 0px 10px;
  font-weight: 600;
  color: #4e4949;
  
}