.Button {
    display: inline-block;
    font-size: 16px;
    letter-spacing: 0.25px;
    /* margin-bottom: 2rem;  sonarqube bug */
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    /* background-color: #ea2839; sonarqube bug */
    border: 0;
    border-radius: 50px;
    /* padding: 17px 20px 15px 20px; */
    padding: 1.4rem 2.5rem 1.4rem 2.5rem;
    margin: 20px 0;
    -webkit-transition: 0.1s ease-in;
    transition: 0.1s ease-in;
    min-width: 140px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background: #f58320;
  }
  .Button:hover {
    background-color: #012b49 !important;
    color: #fff !important;
  }
  .Button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;
    background-color: rgb(139, 144, 148);
    color: rgb(255, 255, 255);
  }
  .Button i {
    margin-left: 10px;
  }
  .Button.Secondary {
    background-color: #ea2839;
  }
  .Button.Small {
    padding: 10px 20px 8px 20px;
    font-size: 14px;
  }
  .Buttonloader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #002137;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    position: relative;
    margin-right:-4rem;
    margin-left: 1.5rem;
    z-index: 1001;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media (max-device-width: 768px) {
    .Button {
      min-width: 50px !important;
      max-width: max-content;
      font-size: 12px;
    }
  }
  