.TextBox {
    border: 1px solid#edeef0;
    border-radius: 4px;
    margin-top: 2px;
    margin-bottom: 20px;
    font-size: 14px;
    background-color: #fff;
    padding: 1.6rem;
    width: 100%;
    display: inline-block;
  }
  .TextBox:disabled {
    opacity: 0.7;
  }
  .TextBoxContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .TextBoxContainer i {
    /* right: 10px; */
    margin-left: -20px;
  }
  .TextBox:-internal-autofill-selected,
  TextBox:-webkit-autofill,
  TextBox:-webkit-autofill:hover,
  TextBox:-webkit-autofill:focus,
  TextBox:-webkit-autofill:active {
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset;
  }
  